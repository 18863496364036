import axios from "axios";
import { getBackendUrl, getBaseUrl } from "./Vars";
import { UserProfile, getUserProfile } from "./backend";



export class AuthManager {

  private static instance: AuthManager;
  private userProfile: UserProfile | null = null;

  private constructor() {
    console.log('get everything from localStorage');

    if (this.isLoggedIn()) {
      console.log('already logged in');
      const profile = localStorage.getItem("profile");
      if (profile) {
        this.userProfile = JSON.parse(profile);
      }
    }
  }

  public static getInstance(): AuthManager {
    if (!AuthManager.instance) {
      console.log('create AuthManager instance');
      AuthManager.instance = new AuthManager();
    }
    return AuthManager.instance;
  }

  private loginListeners: Function[] = [];
  public addLoginListener(listener: Function) {
    this.loginListeners.push(listener);
  }

  private notifyLoginListeners() {
    this.loginListeners.forEach((listener) => listener());
  }

  public async login(oauth: string, code: string) {
    console.log('oauth:', oauth);
    console.log('code:', code);

    const data = {
      code: code,
      "oauth-provider": oauth,
    };
    const res = await axios.post(getBackendUrl() + '/v1/user/token', data);
    console.log('1. get token completed');
    const token = res.data.access;
    const refreshToken = res.data.refresh;
    console.log("New tokens:", token, refreshToken );

    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refreshToken);
    console.log('2. token saved');

    const profile = await getUserProfile();
    console.log('3. get user profile completed');
    console.info(profile);
    this.userProfile = profile;
    localStorage.setItem("profile", JSON.stringify(profile));
    console.log('login success');
    this.notifyLoginListeners();

    /*
    axios.post(getBaseUrl()+`/api/v1/user/token`, data).then((res) => {
      console.log('1. get token completed');
      const token = res.data.access;
      const refreshToken = res.data.refresh;

      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
      console.log('2. token saved');

      // Fetch user profile after successful login
      getUserProfile().then((profile) => {
        console.log('3. get user profile completed');
        console.info(profile);
        this.userProfile = profile;
        localStorage.setItem("profile", JSON.stringify(profile));
        console.log('login success');
      });
    }).catch((err) => {
      console.error(err);
    });
    */
  }

  public setToken(access: string) {
    localStorage.setItem("token", access);
  }

  public setRefreshToken(refresh: string) {
    localStorage.setItem("refresh_token", refresh);
  }

  public getToken(): string | null {
    return localStorage.getItem("token");
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem("refresh_token");
  }

  public isLoggedIn(): boolean {
    return this.getToken() !== null;
  }

  public getUserProfile(): UserProfile | null {
    return this.userProfile;
  }

  public logout(): void {
    console.log('logout');
    this.userProfile = null;
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    this.notifyLoginListeners();
  }
}
