import React, { useEffect, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from "react-chartjs-2";
import { Box, Spinner, styled, Text } from '@chakra-ui/react';
import { getLang } from '../../../lib/utils/backend';
import { Divider } from '@chakra-ui/react'
import { FaLanguage } from 'react-icons/fa';


Chart.register(ArcElement, Tooltip, Legend);

type Language = string;
type ByteCount = number; 

interface LanguageData {
  languages : Record<Language, ByteCount>;
}


function LangChart(props: any) {

    // 상태 정의
    const [langs, setLangs] = useState<LanguageData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

  
    
    // get user
    const userId = props.profile?.user.id;

    useEffect(() => {
      const fetchLanguageData = async () => {
        if (!userId) return;
  
        const MAX_RETRIES = 5;
        let retries = 0;
  
        while (retries < MAX_RETRIES) {
          try {
            const res = await getLang(userId);
            if (res && Object.keys(res.languages).length > 0) {
              setLangs(res);
              console.log("Fetched language data:", res);
              const newDataType = Object.keys(res.languages);
              props.setDataType(newDataType);
              setIsLoading(false);
              return; // 성공적으로 데이터를 가져왔으므로 함수 종료
            } else {
              console.log("Empty language data received, retrying...");
              retries++;
            }
          } catch (err) {
            console.error("Error fetching language data:", err);
            retries++;
          }
  
          // 재시도 전 잠시 대기
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
  
        // 최대 재시도 횟수를 초과한 경우
        setError("Failed to load User's Langs after multiple attempts");
        setIsLoading(false);
      };
  
      fetchLanguageData();
    }, [userId, props.setDataType]);
  

    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <Spinner size="xl" />
        </Box>
      );
    }
  
    if (error) {
      return <Box textAlign="center">{error}</Box>;
    }
  
    if (!langs || Object.keys(langs.languages).length === 0) {
      return <Box textAlign="center">No language data available</Box>;
    }

    const topN = 5; // 상위 5개 언어 표시
    const total = Object.values(langs.languages).reduce((sum, count) => sum + count, 0);
    const sortedEntries = Object.entries(langs.languages).sort((a, b) => b[1] - a[1]);
    const topLanguages = sortedEntries.slice(0, topN);
    const otherSum = sortedEntries.slice(topN).reduce((sum, [, count]) => sum + count, 0);
  
    const languages = [...topLanguages.map(([lang]) => lang + ','), 'Other...'];
    const counts = [...topLanguages.map(([, count]) => count), otherSum];
    const percentages = counts.map(count => (count / total) * 100);
  
    const generateColors = (count:number) => {
      const colors = [];
      for (let i = 0; i < count; i++) {
        colors.push(`hsl(${(i * 360) / count}, 70%, 50%)`);
      }
      return colors;
    };

    const newData = {
      labels: languages,
      datasets: [{
        data: percentages,
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ],
        hoverOffset: 4,
      }],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: "User's Programming Languages"
        },
        tooltip: {
          callbacks: {
            label: function(context: any) {
              const label = context.label || '';
              const value = context.raw;
              const actualValue = counts[context.dataIndex];
              return `${label}: ${value.toFixed(2)}% (${actualValue.toLocaleString()} bytes)`;
            }
          }
        },
      },
    };
  
    return (
      <Box height="fit-content" width="100%">
        <Doughnut 
        style={{maxWidth:'100%'}}
          data={newData}   
          options={options} 
        />
          <Text fontSize='x-large' fontWeight='300' color='transparent' mb='10px'  mt='20px' bgClip='text' bgImage={'linear-gradient(to left, #7928CA, #FF0080)'} >
            Languages
          </Text>
          <Divider orientation='horizontal' />
          <Text color='#718096' fontSize='large' fontWeight='300' >
            {languages.map((it) => <span>{it} </span>)}
          </Text>
      </Box>
    );
  }

export default LangChart;