import { observer } from "mobx-react-lite";
import { Link, Box, Heading,Text, Button, VStack, HStack, Spinner, Spacer, Flex, Menu, MenuButton, MenuList, MenuItem, IconButton, useDisclosure, AlertDialogFooter, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay,  SimpleGrid, useBreakpointValue, Accordion, AccordionItem, AccordionButton, AccordionPanel, Divider, Table, Thead } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { projectController } from "../../store/ProjectController";
import { repositoryController } from "../../store/RepositoryController";
import { FaGithub } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";

import { CiSquareRemove } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { FiGitBranch } from "react-icons/fi";
import { WorkflowEdit } from "./workflowEdit";
import Markdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { newTheme } from "../../../elements/markupTheme";
import { getReadme } from "../../../lib/utils/backend";
import { AddIcon, BellIcon, MinusIcon } from "@chakra-ui/icons";
import AgentContainer from "../workflow/AgentContainer";

interface Bot {
  id: number;
  name: string;
  src: string;
  blocks: string[];
  selected: boolean;
  run: boolean;
}




export const ProjectDetail = observer(() => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isOpen:isDeleteOpen, onOpen:onDeleteOpen, onClose:onDeleteClose } = useDisclosure()
  const [ readme, setReadme ] = useState<string>("");
  const gridTemplateColumns = useBreakpointValue({ base: "1fr", xl: "1fr auto" });
  const gridHeight = useBreakpointValue({ base: "40vh", xl: "calc(100vh - 126px)" });
  const itemWidth = useBreakpointValue({ base: "full", xl: "fit-content" });

  
  const projController = projectController;
  const repoController = repositoryController;

  if(!projController.isInitialized() || !repoController.isInitialized()){
    return <Spinner />
  }
  
  let project = null;
  let repoName = "";
  if(projectId){
    const p = projController.getProjectFromId(projectId);
    if(p){
      project = p;
      repoName = repoController.getRepositoryName(p.repo_url)
    }
  }
  
  const deleteProject = () => {
    if (projectId) {
      projController.deleteProject(projectId);
      navigate('/dashboard');
    }
  }

  // fetch readme.md
  useEffect(() => {
    if(!projectId) return;
    getReadme(projectId).then((res) => {
      setReadme(res);
    }).catch((err) => {
      console.error(err);
      setReadme("Failed to load README.md");
    });

  }, [projectId]);

  
  const cancelRef = React.createRef<HTMLButtonElement>();
  
  
  return (
    <>
      <Box 
      maxW='1700px'
      w='100%'
      h='100%'
      p='10px'
      m='0 auto'
      >      
      <Flex>
        <Box >
          {/* 레포 네임 (헤더) */}
          <Heading>
            <HStack>
              <Text bgGradient="linear(to-tl, pink.200, pink.500)" bgClip="text" >{ repoName }</Text>
            </HStack>
          </Heading>
          <HStack pl='0px' pt='3px' >
            {project && (
            <Box display={'flex'} rounded={'5px'} background={'gray.300'} pl={2} pr={2} pt={1} pb={1}>
              <FiGitBranch size={20}/>
              <VStack pl="10px">
                <Text as='span' fontSize={"small"} >
                { project?.repo_branch }
                </Text>
              </VStack>
              <Link href={project.repo_url} target="_blank" pl={3}>
                <FaGithub size={20}/>
              </Link>
            </Box>
            
            )}
          </HStack>
        </Box>
        <Spacer />
        <Box>
          <Menu closeOnSelect={false}>
          <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaGear />}
              variant='outline'
            />
            <MenuList>
              <MenuItem icon={<CiSquareRemove color="red"/>} onClick={onDeleteOpen}>
                Delete this workflow
              </MenuItem>

            </MenuList>
          </Menu>
        </Box>
      </Flex>
      
      
      {/* ai봇 섹션 */}
      <Box  m='30px 0'>
            <Heading>
              <HStack mb='20px'>
                <Text bgGradient="linear(to-tl, pink.200, pink.500)" bgClip="text" >Agents</Text>
              </HStack>
            </Heading>
   
             {/* AI봇 카드 캐러셀 */}
            <Box w='100%' >
            {project && <AgentContainer projectId={project.id} repoName = {repoName} />}

            </Box>  

            {/* {project && <AiContainer projectId={project.id} />} */}
            {/* {project && <AiContainerMain projectId={project.id} />} */}
      </Box>
      
      
      <SimpleGrid gridTemplateColumns={gridTemplateColumns} spacing={10} minChildWidth='500px'  display='flex' flexDir={'column'}>
        <Box bg='gray.50' mt='20px' p='15px' borderRadius={'10px'} maxH={gridHeight} overflowY='auto' sx={{
          boxShadow: "lg",
        }}>
          <Markdown
            components={ChakraUIRenderer(newTheme)}
            children={readme}
            skipHtml
            />
        </Box>

        <Box mt='50px' >
          <Text fontSize='3xl' as='b'>
            Custom your Workflow
          </Text>
          <Box minW={itemWidth}>
              {project && (
                  <WorkflowEdit project={project} />
              )}
          </Box>
        </Box>
        
      </SimpleGrid> 
      </Box>        
      
      
      {/* 삭제 시 경고창 */}
      <AlertDialog
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete '{repoName}' workflow
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onDeleteClose} ref={cancelRef}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={deleteProject} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
      
      
      
    </>
  );
});
