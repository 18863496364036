import { getSummary } from '../../../lib/utils/backend';
import React, { useCallback, useEffect, useState } from 'react';
import { Spacer, Text, VStack, Spinner } from "@chakra-ui/react";
import styled from '@emotion/styled';


interface SummaryData {
    summary: string[];
    recommendation: string[];
}
interface StyledTextProps {
  fontSize?: string;
}


const BasicText = styled.div<StyledTextProps>`
  color: #718096;
  font-size: ${props => props.fontSize || 'large'};
  font-weight: 300;
  padding-left: 20px;
  text-indent: -20px;
  margin-bottom: 10px;
`;

const TitleText = styled.div<StyledTextProps>`
  font-size: ${props => props.fontSize || 'large'};
  font-weight: 500;
  background-image: linear-gradient(to left, #7928CA, #FF0080);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`;

const SubTitleText = styled.div<StyledTextProps>`
  font-size: ${props => props.fontSize || 'large'};
  font-weight: 300;
  background-image: linear-gradient(to left, #7928CA, #FF0080);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`;

function Summary(props: { profile?: { user: { id: string } } | null }) {
    const [summary, setSummary] = useState<SummaryData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const userId = props.profile?.user.id;

    const fetchSummaryData = useCallback(async () => {
        if (!userId) return;
    
        setIsLoading(true);
        setError(null);
        try {
          const res = await getSummary(userId);
          setSummary(res);
        } catch (err) {
          console.error('Error fetching summary data', err);
          setError('Failed to get summary data');
        } finally {
          setIsLoading(false);
        }
      }, [userId]);

    useEffect(() => {
        fetchSummaryData();
    }, [fetchSummaryData]);

    if (isLoading) {
        return <Spinner />;
    }
    
    if (error) {
        return <Text color="red.500">{error}</Text>;
    }
    
    if (!summary) {
        return <Text>No summary data available.</Text>;
    }


    return (
        <VStack alignItems="flex-start">
          <Text fontWeight="700" fontSize="5xl">Veronica found some insights for you! 🎉</Text>
    
          <TitleText fontSize="xx-large">
            You're really good at coding!
          </TitleText>
          <Spacer />
    
          <BasicText fontSize="large">
            - Veronica has analyzed your repositories and found some interesting insights.
          </BasicText>
          <BasicText fontSize="large">
            - Here's a summary of your projects and recommendations to help you improve your codebase.
          </BasicText>
    
          <SubTitleText fontSize="x-large">
            Summary
          </SubTitleText>
          {summary.summary.map((item, index) => (
            <BasicText key={`summary-${index}`} fontSize="large">- {item}</BasicText>
          ))}
          <Spacer />
          <SubTitleText fontSize="x-large">
            Recommendations
          </SubTitleText>
          {summary.recommendation.map((item, index) => (
            <BasicText key={`recommendation-${index}`} fontSize="large">- {item}</BasicText>
          ))}
        </VStack>
      );
    }
    
    export default Summary;