import axios, { AxiosError } from "axios";
import { getBaseUrl } from "./Vars";
import { AuthManager } from "./AuthManager";
import { PiCoinsBold } from "react-icons/pi";

// -----------------------------------------------------
export interface TokenResponse {
  access: string;
  refresh: string;
};
// 수정
async function refreshToken(){
  const refreshToken = AuthManager.getInstance().getRefreshToken();
  if(!refreshToken){
    throw new Error('No Refresh Token availaber')
  }

  try{
    const response = await axios.post(getBaseUrl() + `/v1/user/token/refresh`, {
      refresh: refreshToken
    })

    const newTokens = response.data as TokenResponse
    AuthManager.getInstance().setToken(newTokens.access)

    AuthManager.getInstance().setRefreshToken(newTokens.refresh)
    return newTokens;
  }catch(error){
    console.error("Token REFREH failed",error)
    AuthManager.getInstance().logout();
    throw error
  }
}


async function ApiWithTokenRefresh<T>(func: () => Promise<T>): Promise<T>{
  const MAX_RETRY = 3; 

  const delay = 500;

  for(let retry = 0; retry<MAX_RETRY; retry++){
    try{
      return await func();
    }catch(e:any){
      if(e instanceof AxiosError && e.response?.status === 401){
        console.info('Token Expired. Attemping refresh, Retry:', retry +1)

        try{
          await refreshToken()
          continue;
        }catch(refreshError){
          console.error('token refresh failed', refreshError)
          AuthManager.getInstance().logout()
          throw new Error('Authentication failed')
        }
      }

      if (retry === MAX_RETRY-1){
        console.error('max retries reached')
        throw e;
      }

      console.info(`request failed, retrying in ${delay}ms....`)
      await new Promise(resolve => setTimeout(resolve, delay))
    }
  }

  throw new Error('unexpected error after retries')

}


//-----------------------------------------------------------------------------
// export function refreshToken() {
//   const data = {
//     refresh: AuthManager.getInstance().getRefreshToken(),
//   };
//   return axios.post(getBaseUrl() + `/user/token/refresh`, data).then((response) => {
//     console.log(response);
//     return response.data as TokenResponse;
//   }).catch((error) => {
//     console.error(error);
//     return null;
//   });
// }
// // -----------------------------------------------------
// function ApiWithTokenRefresh<T>(func: ()=>Promise<T>): Promise<T> {
//   const action = () : Promise<T> => {
//       return func();
//   };
  
//   var retry = 0;
//   const MAX_RETRY = 3;
//   const delay = 500;

//   const retryAction = async () => {
//     while(retry < MAX_RETRY){
//       try{
//         return await action();
//       }catch(e:any ){
//         if(e instanceof AxiosError){
//           if(e.response && e.response.status === 500){
//             //복구 불가능한 에러
//             return Promise.reject(["Internal Server Error"]);
//           }
//           else if(e.response && e.response.status === 409){
//             //중복된 데이터 에러
//             return Promise.reject([e.message]);
//           }
//         }
//         // if 401 error we can retry this action with refresh token
//         console.info("retry: " + retry);
//         console.error(e);
//         retry++;
//         // token refresh
//         refreshToken().then((token) => {
//           if(token){
//             console.log("token refreshed");
//             console.log(token);
//             AuthManager.getInstance().setToken(token.access);
//             AuthManager.getInstance().setRefreshToken(token.refresh);
//           }
//         });

//         await new Promise((resolve) => setTimeout(resolve, delay));
//       }
//     }
//     // every token invalid! then logoff
//     // AuthManager.getInstance().logout();
//     throw new Error("Retry failed");
//     //return Promise.reject(["Failed to get repository list"]);
//   };
//   return retryAction();
// }


function get<T>(url:string): Promise<T> {
  const action = () : Promise<T> => {
    const token = AuthManager.getInstance().getToken();
    console.log(token)
    if (token) {
      return axios.get(getBaseUrl()  +'/v1' + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        return response.data as T;
      })
    }
    else{
      throw new Error("No token");
    }
  };
  return ApiWithTokenRefresh(action);
}
 
function get2<T>(url:string, data:any): Promise<T> {
  const action = () : Promise<T> => {
    const token = AuthManager.getInstance().getToken();
    console.log(token)
    if (token) {
      return axios.get(getBaseUrl() + '/v1' + url, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data
      }).then((response) => {
        return response.data as T;
      })
    }
    else{
      throw new Error("No token");
    }
  };
  return ApiWithTokenRefresh(action);
}

function post<T>(url:string, data:any): Promise<T> {
  const action = () : Promise<T> => {
    const token = AuthManager.getInstance().getToken();
    console.log(token)
    if (token) {
      return axios.post(getBaseUrl() +'/v1' + url, 
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        return response.data as T;
      });
    }
    else{
      throw new Error("No token");
    }
  };
  return ApiWithTokenRefresh(action);
}

export function del(url:string): Promise<void> {
  const action = () : Promise<void> => {
    const token = AuthManager.getInstance().getToken();
    console.log(token)
    if (token) {
      return axios.delete(getBaseUrl() +'/v1' + url, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {

      });
    }
    else{
      throw new Error("No token");
    }
  };
  return ApiWithTokenRefresh(action);
}

export function patch<T>(url:string, data:any): Promise<T> {
  const action = () : Promise<T> => {
    const token = AuthManager.getInstance().getToken();
    console.log(token)
    if (token) {
      return axios.patch(getBaseUrl() +'/v1' + url, 
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        return response.data as T;
      });
    }
    else{
      throw new Error("No token");
    }
  };
  return ApiWithTokenRefresh(action);
}

// -----------------------------------------------------
export interface Repository{
  repo_name: string;
  repo_url: string;
};

export function getRepositories(): Promise<Array<Repository>> {
  const action = () : Promise<Array<Repository>> => {
    const token = AuthManager.getInstance().getToken();
    if (token) {
      return axios.get(getBaseUrl() + `/v1/utils/repos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        return response.data as Array<Repository>;
      });
    }
    else{
      throw new Error("No token");
    }
  };
  return ApiWithTokenRefresh(action);
}

export function getBranches(repoUrl:string): Promise<Array<string>> {
  return get<Array<string>>(`/utils/branches?repo=` + repoUrl);
}
// -----------------------------------------------------

export interface User{
  id: string;
  user_email: string;
  oauth_provider: string;
  object:string;
};

export interface UserProfile {
  date_joined: string;
  profile_image?: string;
  user:User;
};

export function getUserProfile(): Promise<UserProfile> {
  return get<UserProfile>(`/user/profile`);
}


// -----------------------------------------------------
/**
 * {
    "object": "project",
    "id": "42b8a077-bfdb-4a48-afd9-749b6260557f",
    "user": {
      "object": "user",
      "id": "02cb60c5-b169-4df2-ab75-249b19d4407e"
    },
    "repo_url": "https://github.com/example/repository",
    "repo_branch": "main",
    "last_update": "2024-03-15T14:04:39.462843+09:00"
  },
 */
export interface Project{
  id: string;
  user: User;
  repo_url: string;
  repo_branch: string;
  last_update: string;
};

export function getProjects(): Promise<Array<Project>> {
  return get<Array<Project>>(`/projects`);
}


// -----------------------------------------------------
export function createProject(repoUrl:string, repoBranch:string): Promise<Project> {
  return post<Project>(`/projects/`, {
    "repo_url": repoUrl,
    "repo_branch": repoBranch,
  });
}

// -----------------------------------------------------
export function deleteProject(projectId:string): Promise<void> {
  return del(`/projects/` + projectId);
}
// -----------------------------------------------------
export function getProject(projectId:string): Promise<Project> {
  return get<Project>(`/projects/` + projectId);
}
// -----------------------------------------------------
/**
 {
        "object": "block_config",
        "id": "92dce071-72d6-416a-857d-405ba84e5aca",
        "name": "Commenter",
        "desc": "Add comments to function",
        "icon": "https://s3.ap-northeast-2.amazonaws.com/veronica-test.site/blocks/blockconfig/887e2b4aad204c8c826870d956456718.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATCKAPDYQVTSHXLFQ%2F20240521%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Date=20240521T141201Z&X-Amz-Expires=600&X-Amz-SignedHeaders=host&X-Amz-Signature=18fdc720efda60d6e129771ca82f939cfd9714c82fbb2191fe0a4356e8c6caad",
        "is_termination": false
    }
 */
export interface BlockConfig{
  object:string
  id:string
  name:string
  desc?:string
  icon?:string
  is_termination:boolean
}

export function getBlockConfigs(): Promise<Array<BlockConfig>> {
  return get<Array<BlockConfig>>(`/blocks/config`);
}
/*
{
    "object": "block",
    "id": "7adb732a-7c9a-41aa-8fdd-efd50f9df88b",
    "index": 0,
    "config": {
      "object": "block_config",
      "code": "ZERO",
      "name": "Default Block Configuration"
    }
  }
*/

// -----------------------------------------------------

/*
[
  {
    "object": "sequence",
    "id": "23693251-0e70-492e-b9eb-0470892b8234",
    "target": {
      "include": [
        ".py"
      ],
      "exclude": []
    },
    "length": 0,
    "routine": [],
    "schedule": {
      "listen": "cron",
      "detail": "* * * 15 *"
    }
  }
]
*/
export interface SequenceConfig{
  object:string
  id:string
  target:{
    include:Array<string>
    exclude:Array<string>
  }
  length:number
  routine:Array<string>
  schedule:{
    listen:string
    detail:string
  }
  is_listening:boolean
  is_running:boolean
}

export interface SequenceCreateData{
  target:{
    include:Array<string>
    exclude:Array<string>
  },
  schedule:{
    listen:string
    detail:string | string[]
  }
}

export function getProjectSequences(projectId:string): Promise<Array<SequenceConfig>> {
  return get<Array<SequenceConfig>>(`/projects/`+projectId+`/sequences`);
}

export function getSequence(sequenceId:string): Promise<SequenceConfig> {
  return get<SequenceConfig>(`/sequences/`+sequenceId);
}

interface SequenceTargetFiles{
  page:number
  count:number
  path_list:Array<string>
};
export function getSequenceTargetFiles(sequenceId:string): Promise<SequenceTargetFiles> {
  //https://api.veronica-test.site/v1/sequences/:sequence_id/targets?page=2
  return get<SequenceTargetFiles>(`/sequences/`+sequenceId+`/targets?page=1`);
}

export function createProjectSequence(projectId:string, data:SequenceCreateData): Promise<SequenceConfig> {
  return post<SequenceConfig>(`/projects/`+projectId+`/sequences`, data);
}
// -----------------------------------------------------
export function deleteProjectSequence(sequenceId:string): Promise<void> {
  return del(`/sequences/` + sequenceId);
}
// -----------------------------------------------------
/*
[
  {
    "object": "block",
    "id": "7adb732a-7c9a-41aa-8fdd-efd50f9df88b",
    "index": 0,
    "config": {
      "object": "block_config",
      "code": "ZERO",
      "name": "Default Block Configuration"
    }
  },
  {
    "object": "block",
    "id": "ef98081d-73b4-4ecc-b3a3-1ff5565924af",
    "index": 1,
    "config": {
      "object": "block_config",
      "code": "ZERO",
      "name": "Default Block Configuration"
    }
  }
]
*/
export interface Block{
  object:string
  id:string
  index:number
  is_running:boolean
  config:BlockConfig
}
const sampleSequencesBlock = [
  {
    "object": "block",
    "id": "7adb732a-7c9a-41aa-8fdd-efd50f9df88b",
    "index": 0,
    "config": {
      "object": "block_config",
      "code": "ZERO",
      "name": "Default Block Configuration"
    }
  },
  {
    "object": "block",
    "id": "ef98081d-73b4-4ecc-b3a3-1ff5565924af",
    "index": 1,
    "config": {
      "object": "block_config",
      "code": "ZERO",
      "name": "Default Block Configuration"
    }
  }
]
export function getSeqeunceBlocks(sequenceId:string): Promise<Array<Block>> {
  return get<Array<Block>>(`/sequences/`+sequenceId+`/blocks`);
}

export function addSeqeunceBlocks(sequenceId:string, blockId:string, index:number): Promise<Block> {
  const postData = {
    config_id: blockId,
    ...(index !== -1 && { index }),
  };
  return post<Block>(`/sequences/${sequenceId}/blocks`, postData);
}

export function deleteBlock(blockId:string): Promise<void> {
  return del(`/blocks/`+blockId);
}

export function patchBlock(blockId:string, data:any): Promise<Block> {
  return patch<Block>(`/blocks/`+blockId, data);
}

export function startSequence(sequenceId:string): Promise<void> {
  return post<void>(`/sequences/`+sequenceId+`/start`, {});
}

export function stopSequence(sequenceId:string): Promise<void> {
  return post<void>(`/sequences/`+sequenceId+`/stop`, {});
}

export function getReadme(projectId:string): Promise<string> {
  return get<string>(`/projects/`+projectId+`/readme`);
}


export interface Stat{
  date: string
  count: number
}
export function getStat(projectId:string): Promise<Array<Stat>>{
  return get<Array<Stat>>(`/projects/`+projectId+`/stats`);
}
interface LanguageData {
  languages: {
    [key: string]: number;
  };
}
export function getLang(userId: string): Promise<LanguageData> {
  return get<LanguageData>(`/dashboard/${userId}/langs`);
}

interface SummaryData{
  summary : [string],
  recommendation : [string]
}
export function getSummary(userId: string) : Promise <SummaryData>{
  return get<SummaryData>(`/dashboard/${userId}/summary`)
}


// frameworks 
interface FrameworksData {
  [key: string]: number;
}

export function getFrameworks(userId : string) : Promise<FrameworksData>{
  return get<FrameworksData>(`/dashboard/${userId}/frameworks`)
}


// repo data
interface RepoData {
  repo_list: string[];
  total_repo: number;
  repo_eval: {
    active_repo: number;
    inactive_repo: number;
  };
}


export function getRepoData(userId : string) : Promise<RepoData>{
  return get<RepoData>(`/dashboard/${userId}/repo_data`)
}