import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Text, Button, Image, IconButton, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import aibot from "../../../assets/images/aibot.jpg";
import aibotwoman from '../../../assets/images/aiRobotWoman.jpg'
import agentLists from '../../../elements/agentsLists.json'
import { FaMinus, FaPlay, FaPlus } from "react-icons/fa"; 
import { set } from 'mobx';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SelectedBot from './SelectedBot';


//Bot 인터페이스
interface Bot {
    id: number;
    name: string;
    src: string;
    blocks: string[];
    selected: boolean;
    run: boolean;
    level:number;
}


const AgentCarousel= ({projectId}:any) => {
    // total agents list 
    const [agentList, setAgentList] = useState<Bot[]>();

    // GET agents list using Mock Server
    useEffect(()=>{
      const fetchData = async () =>{
        try{
          const response = await axios({
            method:'get',
            url: `https://596f46e3-cd05-4d21-9d0e-8592bcc9349f.mock.pstmn.io/agents/agent_list`,
          });
          const mockAgentList = response.data;
           // localStorage에서 프로젝트별 선택 상태 가져오기
          const saved = localStorage.getItem(`${projectId}`);
          const savedSelections = saved ? JSON.parse(saved) : [];
          // Mock 데이터와 저장된 선택 상태 결합
          const combinedAgentList = mockAgentList.map((agent: Bot) => ({
            ...agent,
            selected: savedSelections.some((savedAgent: Bot) => savedAgent.id === agent.id)
          }));
          
          setAgentList(combinedAgentList);
          setSelectedBots(savedSelections);

          //-----------------------------------//
          // setAgentList(response.data);
          // console.log('Mock Server Comopleted' , agentList)
          // console.log('Mock Server Comopleted' , typeof(agentList))
          // agentList?.map((it)=>(
          //   console.log(it)
          // ))
        }catch(error){
          console.log('에러')
        }
      };
      fetchData();
    }, []);

    const [selectedBots, setSelectedBots] = useState<Bot[]>(() => {
      const saved = localStorage.getItem(`${projectId}`);
      return saved ? JSON.parse(saved) : []
    })
    useEffect(() => {
      const saved = localStorage.getItem(`${projectId}`);
      setSelectedBots(saved ? JSON.parse(saved) : []);
    }, [projectId]);
    

    //  컴포넌트 마운트 시 선택된 봇 상태 복원
    useEffect(() => {
      setAgentList(prevList =>
        prevList?.map(bot => ({
          ...bot,
          selected: selectedBots.some(selectedBot => selectedBot.id === bot.id)
        }))
      );
    }, [selectedBots, projectId]);

    const [currentIndex, setCurrentIndex] = useState(2);
    const nextCard = () => setCurrentIndex((prevIndex) => (prevIndex + 1) % agentLists.length);
    const prevCard = () => setCurrentIndex((prevIndex) => (prevIndex - 1 + agentLists.length) % agentLists.length);

  
    // 선택된 봇이 변경될 때마다 로컬 스토리지에 저장 : projectId 키에 selected 봇들
    useEffect(() => {
      window.localStorage.setItem(
        `${projectId}`,
        JSON.stringify(selectedBots)
      );
    }, [selectedBots, projectId]);



    function handleSelect(botId: number) {
      const bot = agentList?.find(b => b.id === botId);
      if (!bot) {
        console.log('봇이 없습니다.');
        return;
      }
    
      if (bot.selected) {
        // 선택 해제
        setSelectedBots(prevSelected => prevSelected.filter(b => b.id !== botId));
      } else {
        // 선택
        if (selectedBots.length >= 3) {
          toast({
            title: "선택 제한",
            description: "최대 3개의 봇만 선택할 수 있습니다.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        setSelectedBots(prevSelected => [...prevSelected, { ...bot, selected: true }]);
      }
    
      // agentList 업데이트
      setAgentList(prevList =>
        prevList?.map(b => b.id === botId ? { ...b, selected: !b.selected } : b)
      );
    }

    const {isOpen, onOpen, onClose} = useDisclosure()
    const finalRef = React.useRef(null)

    // 봇 선택 아코디언 실행
    const [show, setShow] = useState(false)
    const colorChange = useRef()
    const handleChange=()=>{
      setShow(!show)
      // colorChange.current.style = 'opacity:0; height:auto; display:block; overflow:hidden'
    }
    console.log(show)


    
   
  


  return (
  <>    
    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size='xl'  blockScrollOnMount={false} isCentered>
        <ModalOverlay />
        <ModalContent maxW='900px'>
          <ModalHeader>Choose Your Agents</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

              <Box w='100%' minHeight="fit-content" position="relative" 
                 overflow="hidden" 
                // bgColor='gray.300'
                bgColor='gray.700'
                // h='350px'
                borderRadius='10px'
                // opacity={show===false?'0' :'100%'}
                // style={{opacity: (show && selectedBots.length <3) ? '1' : '0', height:(show && selectedBots.length <3) ? '100%' : '0' }}
              >
                <Flex justifyContent="center" alignItems="center" height="500px" >
                  {agentList?.map((bot, index) => {
                    const offset = index - currentIndex;
                    const isVisible = Math.abs(offset) <= 2;
                    const zIndex = 2 - Math.abs(offset);
                    // console.log(offset)
                    // console.log(typeof(offset))
                    
                    return (
                      <>
                      <Box
                        key={index}
                        position="absolute"
                        // width={offset === 0 ? "250px" : "200px"}
                        w='250px'
                        transform={`translateX(${offset * 60}%) scale(${1 - Math.abs(offset) * 0.2})`}
                        opacity={isVisible ? 1 : 0}
                        zIndex={zIndex}
                        transition="all 0.3s ease-out"
                        cursor="pointer"
                      >
                        <Box
                          bg="gray.800"
                          borderRadius="xl"
                          overflow="hidden"
                          boxShadow="xl"
                          textAlign="center"
                          p='30px 20px 20px 20px'
                        >
                          <Image src={aibotwoman} alt={bot.name} mb={4} mx="auto"   w='200px' h='180px' objectFit={'cover'} borderRadius='10px'/>
                          <Text fontSize="xl" fontWeight="bold" color="white">{bot.name}</Text>
                          <Text fontSize="sm" color="gray.400">{bot.blocks}</Text>
                          {offset === 0 && (
                            bot.selected ? 
                            <Button mt={4}  onClick={(e)=> handleSelect(bot.id) } colorScheme={bot.selected ? 'pink' : 'purple'} name='minus'>
                            <FaMinus/>
                            </Button>
                            :
                            <Button mt={4}  onClick={(e)=> handleSelect(bot.id)} colorScheme={bot.selected ? 'pink' : 'purple'} name='plus'>
                              <FaPlus/>
                            </Button>
                          )}
                        </Box>
                      </Box>
                      </>
                    );
                  })}
                </Flex>
            
                <IconButton
                  aria-label="Previous card"
                  icon={<ChevronLeftIcon />}
                  onClick={prevCard}
                  position="absolute"
                  left="10%"
                  top="50%"
                  zIndex = '1000'
                  transform="translateY(-50%)"
                />
                <IconButton
                  aria-label="Next card"
                  icon={<ChevronRightIcon />}
                  onClick={nextCard}
                  position="absolute"
                  right="10%"
                  top="50%"
                  zIndex = '1000'
                  transform="translateY(-50%)"
                />
            
                <Flex justifyContent="center" position="absolute" bottom="10%" width="100%">
                  {agentList?.map((_, index) => (
                    <Box
                      key={index}
                      width="10px"
                      height="10px"
                      borderRadius="full"
                      bg={index === currentIndex ? "blue.400" : "gray.600"}
                      mx={1}
                    />
                  ))}
                </Flex>
              </Box>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='pink' mr={3} onClick={onClose}>
              Close
            </Button>
           
          </ModalFooter>
        </ModalContent>
    </Modal>
    
    <Box w='100%'display='flex' flexDir='column' gap='2'>
        <Box w='100%' display='flex' flexDir='row' gap='20px'  bgColor='gray.300' borderRadius='10px'
        position='relative'
        p='30px'
        overflow={'auto'}
        >
          {/* selected agents max 3  */}
          {selectedBots.map((bot)=>(
            bot.selected === true
            ?
            <SelectedBot bot={bot} handleSelect={handleSelect}/>
            : <></>
          )
          )}
            
            <Button
                opacity = {selectedBots.length >= 3 ? '0' : '100'}
                // opacity='0'
                // w={show === true ? '20px' : '300px'}
                w='250px'
                h='405px'
                bg="gray.500"
                // onClick={()=> setShow(!show)}
                onClick={onOpen}
                borderRadius="xl"
                overflow="hidden"
                boxShadow="xl"
                // bgImage={aibot}
                textAlign="center"
                p={4}
                color='white'
                fontSize='8xl'
            >
                <FaPlus  opacity='0.7'/>
            </Button>
        </Box>

        
.



    </Box>

    
    

    </>
  );
};

export default AgentCarousel;

function toast(arg0: { title: string; description: string; status: string; duration: number; isClosable: boolean; }) {
  throw new Error('Function not implemented.');
}
