
import { observer } from "mobx-react-lite";
import { Box, Center, Grid, GridItem, HStack, Text, VStack, keyframes, useBreakpointValue } from "@chakra-ui/react";
import HText from "../../../elements/hText";
import { AuthManager } from "../../../lib/utils/AuthManager";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { newTheme } from "../../../elements/markupTheme";
import LangChart from "./LangChart";
import { getLang } from "../../../lib/utils/backend";
import Summary from './Summary'; 
import Frameworks from "./Frameworks";
import RepositoryAcitivity from './RepositoryActivity';



const typing = keyframes`
  0% { width: 0 }
  30% { width: 100% 
`

const blink = keyframes`
  from, to { border-color: transparent }
  50% { border-color: black }
`

export const DashboardHome = observer (() => {
  const [dataType, setDataType] =  useState<string[]>([]) ;  // 언어
  const profile = AuthManager.getInstance().getUserProfile();
  // get name of user
  let name = profile?.user.user_email;
  try {
    name = profile?.user.user_email.split('@')[0];
  } catch (error) {
    console.log('error:', error);
  }
  // const summary = 

  const loadingText = [
    "Veronica is diligently gathering data from your repositories... 🕵️‍♀️",
    "Analyzing your codebase for valuable insights... 🧠",
    "Crunching numbers and generating reports... 📊",
    "Searching for hidden patterns and trends... 🔍",
    "Compiling a comprehensive overview of your projects... 📚",
    "Veronica is hard at work to provide you with the best insights... 💪",
    "Preparing a personalized dashboard just for you... 🎨",
    "Uncovering new opportunities for optimization... 💡",
    "Synthesizing data to create actionable recommendations... 🧩",
    "Veronica is almost done, just putting the final touches... 🎀"
   ];

  

  const copied_data = ""
  
  

  let markdown = `
  # Veronica found some insights for you! 🎉
  ## You really good at coding!
  - Veronica has analyzed your repositories and found some interesting insights. 
  - Here's a summary of your projects and recommendations to help you improve your codebase.
  ### Summary
  - Veronica analyze Recent activities and you are doing great! Keep up the good work! 🚀
  - Some automation workflows are not optimized. You can improve your workflow by automating repetitive tasks and reducing manual work.
  - Start automate workflow for every project. It will save your time and effort.

  ### Recommendations
  - Some repositories have high code complexity. 
  - You can reduce complexity by refactoring your code and breaking it down into smaller, more manageable parts.
  - Start create new workflow for repository: 'project-1'. It will help you to automate repetitive tasks and improve your productivity.
  `;

  

  let markdown2 = `
  ## About VERONICA
  #### What is VERONICA?
  VERONICA is a code analysis tool that helps you understand your codebase better. It provides you with insights into your projects, helping you make better decisions and optimize your workflow.
  #### How does it work?
  VERONICA connects to your GitHub repositories and analyzes your codebase. It looks for patterns, trends, and potential issues, and provides you with actionable recommendations to improve your projects.
  #### Why use VERONICA?
  - Gain insights into your projects
  - Identify areas for improvement
  - Optimize your workflow
  - Make better decisions
  - Save time and effort
  #### How to get started?
  Simply connect your GitHub account to VERONICA and let it do the rest. VERONICA will analyze your repositories and provide you with a personalized dashboard with valuable insights and recommendations.
  `

  let markdown3 = ` 
  ### Overview
  ---
  - **Languages:** ${dataType}  
  - **Frameworks:** React, Node.js
  - **Tools:** ESLint, Prettier
  ### Repository health
  ---
  - **Total repositories:** 5
  - **Healthy repositories:** 3
  - **Unhealthy repositories:** 2
  ### Code quality
  ---
  - **Code duplication:** 15%
  - **Code complexity:** High
  - **Code smells:** 5

  `

  const [currentText, setCurrentText] = useState(loadingText[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentText(loadingText[Math.floor(Math.random() * loadingText.length)]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const columns = useBreakpointValue({ base: 1, "xl": 5 });

 



  return (
      <Box height="calc(100vh - 40px)" display="flex" flexDirection="column" minW={'800px'} h='fit-content'>
        <Center height="20%">
          <Box>
            <HStack >
            <HText text='Hello, ' />
            <Text fontSize='xxx-large' fontWeight={700}>
            {name}!
            </Text>
            </HStack>

            <HStack >
            <Text fontSize='xxx-large' fontWeight={700}>
            Welcome to
            </Text>
            <HText text='VERONICA' />
            </HStack>
            <VStack maxWidth={'fit-content '}>
              <Text
                  fontSize='large' 
                  fontWeight={400}
                  overflow='hidden'
                  whiteSpace='nowrap'
                  overflowX={'hidden'}
                  borderRight='0.15em solid black'
                  animation={`${typing} 3s steps(30, end) infinite, ${blink} .75s step-end infinite`}
                >
                  {currentText}
                </Text>
            </VStack>
          </Box>
        </Center>

        <Center flex="1" >
          <Box p='20px' bg="white" borderRadius={'10px'} w='100%' h='100%' mt='20px' style={{boxShadow: '0 0 3px rgba(0,0,0,0.2)'}}>
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={10}>
            <GridItem>
              <Box p='10px'  h='100%'>
                <LangChart profile={profile}  dataType={dataType} setDataType={setDataType}/>
                <Frameworks profile={profile}  />
                <RepositoryAcitivity  profile={profile} />
                </Box>
            </GridItem>

            <GridItem colSpan={3}>
                <Box p='10px'>
                  <Summary  profile={profile} />
                </Box>
            </GridItem>


            <GridItem>
                <Box bg='gray.50' p='10px' borderRadius={'10px'}>
                  <Markdown
                    components={ChakraUIRenderer(newTheme)}
                    children={markdown2}
                    skipHtml
                  />
                </Box>
              </GridItem>
            </Grid>
          </Box>
        </Center>
        
      </Box>
  );

});
