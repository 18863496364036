import { getFrameworks } from '../../../lib/utils/backend';
import React, { useCallback, useEffect, useState } from 'react';
import {Box, Divider, Flex, Spinner, Text} from "@chakra-ui/react";

interface FrameworksData {
    [key: string]: number;
  }
function Frameworks(props: any) {
    const [frameworks, setFrameworks] = useState<FrameworksData>({});
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    // 유저 정보 가져오기
    const userId = props.profile?.user.id;

    useEffect(() => {
        const fetchFrameworkData = async () => {
        if (!userId) return;

        setIsLoading(true);
        setError(null);

        try {
            const res = await getFrameworks(userId);
            if (res && Object.keys(res).length > 0) {
            setFrameworks(res);
            } else {
            setError("No framework data available");
            }
        } catch (err) {
            console.error('Error fetching framework data:', err);
            setError(err instanceof Error ? err.message : 'Failed to get framework data');
        } finally {
            setIsLoading(false);
        }
        };

        fetchFrameworkData();
    }, [userId]);

    if (isLoading) {
        return (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <Spinner size="xl" />
        </Box>
        );
    }

    if (error) {
        return <Box textAlign="center">{error}</Box>;
    }

    if (!frameworks || Object.keys(frameworks).length === 0) {
        return <Box textAlign="center">No framework data available</Box>;
    }


    return (
        <Box mt='20px'>
          <Text fontSize='x-large' fontWeight='300' color='transparent' mb='10px' mt='10px' bgClip='text' bgImage={'linear-gradient(to left, #7928CA, #FF0080)'}>
            Frameworks
          </Text>
          <Divider orientation='horizontal' />
          <Box color='#718096' fontSize='large' fontWeight='300'>
            {Object.entries(frameworks).map(([framework, count]) => (
              <Text key={framework}>{framework}: {count}</Text>
            ))}
          </Box>
        </Box>
      );
}

export default Frameworks;