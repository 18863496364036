import { getRepoData } from '../../../lib/utils/backend';
import { Box, Divider, HStack, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useCallback, useState } from 'react';

interface RepoData {
    repo_list: string[];
    total_repo: number;
    repo_eval: {
      active_repo: number;
      inactive_repo: number;
    };
  }




function RepositoryAcitivity(props: { profile?: { user: { id: string } } | null }) {
    const [repodata, setRepodata] = useState<RepoData|null>(null);
    // 로딩 처리
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // 에러 처리
    const [error, setError] = useState<string|null>(null);



    // get user
    const userId = props.profile?.user.id;

    
    useEffect(() => {
        async function fetchData() {
            if (!userId) {
                setIsLoading(false);
                return;
            }

            try {
                const res = await getRepoData(userId);
                setRepodata(res);
            } catch (err) {
                console.error('Error Fetching Repo activity', err);
                setError('Failed to get repo data');
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [userId]);

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <Text color="red.500">{error}</Text>;
    }

    if (!repodata) {
        return <Text>No Repo data available.</Text>;
    }

    const active = repodata.repo_eval['active_repo'];
    const inactive = repodata.repo_eval['inactive_repo'];

    console.log(active)
    console.log(inactive)

    

    
    return (
        <Box height="300px" width="100%">

            <Text fontSize='x-large' fontWeight='300' color='transparent' mb='10px'  mt='20px' bgClip='text' bgImage={'linear-gradient(to left, #7928CA, #FF0080)'}>
            Repository
            </Text>
            <Divider orientation='horizontal'  />

            <Text color='#718096' fontSize='large' fontWeight="300">Total Repository : {repodata.total_repo}</Text>
            <Text color='#718096' fontSize='large' fontWeight="300">Active Respository : {active}</Text>
            <Text color='#718096' fontSize='large' fontWeight="300">Inactive Respository : {inactive}</Text>
        
        </Box>
    );
}

export default RepositoryAcitivity;


